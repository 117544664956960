import React from "react"
import { Button } from "antd"
import "../styles/contactus.sass"
import { Link } from "gatsby"

const ContactUs = () => {
  return (
    <section className="contact-us-section">
        <h1 className="contact-us-section-title">
          Talk to us today to start working smarter.
        </h1>
        <Link to="contact">
          <Button shape="round" ghost>
            contact us
          </Button>
        </Link>
    </section>
  )
}

export default ContactUs
